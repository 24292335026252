// for vuetify polyfilling compatible with chromium 57 as used by ez2view 7
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'bootstrap';
import '@/apps/timeSeriesViewer/css/common.less';

// Ensure logger is set up before including further libraries
import * as log from '@/gr/common/log';
log.logger.logEvents.subscribe(new log.ConsoleObserver());

// Initialize moment to use ISO format
import moment from 'moment';
moment.fn.toJSON = function () {
  return this.format();
};

// Initialize knockout helpers (this registers bindings that otherwise will fail silently)
import '@/common/knockout-helpers';

import $ from 'jquery';

$.noConflict();

// Workaround to handle the upgrade of jquery to 3.0
// The breaking change is discussed under the heading: "Error cases don’t silently fail" here http://blog.jquery.com/2016/06/09/jquery-3-0-final-released/
// Solution copied from the migration plugin: http://code.jquery.com/jquery-migrate-3.0.0.js
const oldOffset = $.fn.offset;

// eslint-disable-next-line
$.fn.offset = function (this: any) {
  const elem = this[0];
  const origin = { top: 0, left: 0 };

  if (!elem || !elem.nodeType) {
    return origin;
  }

  const docElem = (elem.ownerDocument || document).documentElement;
  if (!$.contains(docElem, elem)) {
    return origin;
  }

  // eslint-disable-next-line
  return oldOffset.apply(this, arguments as any) as any;
};

declare global {
  interface Array<T> {
    removeIfExists(this: Array<T>, value: T): boolean;
  }
}

Array.prototype.removeIfExists = function (value: unknown): boolean {
  const index = this.findIndex((x) => x === value);
  if (index === -1) return false;
  this.splice(index, 1);
  return true;
};
