import { ActionContext, Module } from 'vuex';
import { IRootState } from '.';
import { ArtifactEntryPointArgs } from '@/repositories';

// state
interface IArtifactStoreState {
  artifactEntryPointArgs: ArtifactEntryPointArgs | undefined;
}

// vuex module
class ArtifactStore implements Module<IArtifactStoreState, IRootState> {
  namespaced = true;

  // state
  state: IArtifactStoreState = {
    artifactEntryPointArgs: undefined
  };

  // getters
  getters = {
    artifactEntryPointArgs(s: IArtifactStoreState): ArtifactEntryPointArgs | undefined {
      return s.artifactEntryPointArgs;
    }
  };

  // mutations
  mutations = {
    loadArtifactEntryPointArgs(s: IArtifactStoreState, value: ArtifactEntryPointArgs): void {
      s.artifactEntryPointArgs = value;
    }
  };

  // actions
  actions = {
    async loadArtifactEntryPointArgs({ rootState, commit }: ActionContext<IArtifactStoreState, IRootState>, id: string): Promise<void> {
      const artifactEntryPointArgs = await rootState.container.entryPointArgsApiClient.artifacts(id);
      commit('loadArtifactEntryPointArgs', artifactEntryPointArgs);
    }
  };
}

export { IArtifactStoreState, ArtifactStore };

// export an instance of the store by default
export default new ArtifactStore();
