// Do not import large dependencies into this file as it is used by apps that must have a small file size

export interface IDisposable {
  dispose(): void;
}

type DisposableLike = IDisposable | (() => void);

/** Converts a lambda to a disposable */
export class Disposable implements IDisposable {
  static empty = {
    dispose: () => {
      // do nothing
    }
  };

  static wrap(disposable: DisposableLike): IDisposable {
    if (this.isDisposable(disposable)) {
      return disposable;
    } else if (typeof disposable === 'function') {
      return new Disposable(disposable);
    } else {
      throw new Error('Object is not a disposable or a function');
    }
  }

  static isDisposable(disposable: DisposableLike): disposable is IDisposable {
    return disposable && typeof (disposable as IDisposable).dispose === 'function';
  }

  private _disposed = false;

  constructor(private _dispose: () => void) {}

  dispose(): void {
    if (this._disposed) return;
    this._disposed = true;
    this._dispose();
  }
}

/** Stores many disposables */
export class CompositeDisposable implements IDisposable {
  private _disposables: IDisposable[] = [];
  private _disposed = false;

  constructor(disposables?: IDisposable[]) {
    if (disposables) {
      this._disposables = disposables.map((d) => d);
    }
  }

  add(disposable: DisposableLike) {
    disposable = Disposable.wrap(disposable);

    if (this._disposed) {
      disposable.dispose();
    } else {
      this._disposables.push(disposable);
    }
    return this;
  }

  dispose(): void {
    if (this._disposed) return;
    this._disposed = true;
    this._disposables.reverse().forEach((d) => d.dispose());
    this._disposables = [];
  }
}

/** Stores a disposable that can be changed and which causes the previous disposable to be disposed */
export class SerialDisposable implements IDisposable {
  private _disposable!: IDisposable;
  private _disposed = false;

  setDisposable(disposable: DisposableLike) {
    disposable = Disposable.wrap(disposable);
    if (this._disposed) {
      disposable.dispose();
    } else {
      if (this._disposable) this._disposable.dispose();

      this._disposable = disposable;
    }
  }

  dispose(): void {
    if (this._disposed) return;
    this._disposed = true;
    if (this._disposable) {
      this._disposable.dispose();
      this._disposable = Disposable.empty;
    }
  }
}
