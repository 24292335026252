import ko from 'knockout';
import { CompositeDisposable } from '@/gr/common/disposable';

declare global {
  interface KnockoutSubscribableFunctions<T> {
    subscribeChanged(callback: (newValue: T, oldValue: T) => void): CompositeDisposable;
  }
}

ko.subscribable.fn.subscribeChanged = function (callback: (newValue: unknown, oldValue: unknown) => void): CompositeDisposable {
  const self = this as KnockoutObservable<unknown>;
  const subscriptions = new CompositeDisposable();
  let oldValue: unknown;
  subscriptions.add(
    self.subscribe(
      (_oldValue) => {
        oldValue = _oldValue;
      },
      self,
      'beforeChange'
    )
  );

  subscriptions.add(
    self.subscribe((newValue) => {
      callback(newValue, oldValue);
    })
  );

  return subscriptions;
};
