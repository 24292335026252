import _ from 'lodash';
import { LogLevel, LogEvent } from './logging/core';
import { Observable, IObserver, Subject } from './rx';
import { ConsoleObserver } from './logging/consoleObserver';

export * from './logging/core';
export * from './logging/rx';
export * from './logging/consoleObserver';

export class Logger implements IObserver<LogEvent> {
  private _logEvents = new Subject<LogEvent>();
  private _currentLogLevel = LogLevel.INFORMATION;

  logEvents: Observable<LogEvent>;

  constructor() {
    this.logEvents = this._logEvents;
  }

  setLogLevel(logLevel: LogLevel) {
    this._currentLogLevel = logLevel;
  }

  isLogLevelEnabled(logLevel: LogLevel) {
    return logLevel.isPriorityGreaterThanOrEqualTo(this._currentLogLevel);
  }

  onNext(event: LogEvent) {
    if (!this.isLogLevelEnabled(event.level)) return;

    this._logEvents.onNext(event);
  }

  event(logLevel: LogLevel, format: string, args: any[], exception?: any) {
    if (!this.isLogLevelEnabled(logLevel)) return;

    if (!_.isString(format)) {
      console.warn(`${format} is not a string`);
      args.push(format);
      format = 'Invalid message template specified';
    }

    const newEvent = LogEvent.create(logLevel, format, args, exception);

    this._logEvents.onNext(newEvent);
  }

  fatal(format: string, ...args: any[]) {
    this.event(LogLevel.FATAL, format, args);
  }
  error(format: string, ...args: any[]) {
    this.event(LogLevel.ERROR, format, args);
  }
  warning(format: string, ...args: any[]) {
    this.event(LogLevel.WARNING, format, args);
  }
  information(format: string, ...args: any[]) {
    this.event(LogLevel.INFORMATION, format, args);
  }
  debug(format: string, ...args: any[]) {
    this.event(LogLevel.DEBUG, format, args);
  }
  verbose(format: string, ...args: any[]) {
    this.event(LogLevel.VERBOSE, format, args);
  }

  fatalEx(exception: any, format: string, ...args: any[]) {
    this.event(LogLevel.FATAL, format, args, exception);
  }
  errorEx(exception: any, format: string, ...args: any[]) {
    this.event(LogLevel.ERROR, format, args, exception);
  }
  warningEx(exception: any, format: string, ...args: any[]) {
    this.event(LogLevel.WARNING, format, args, exception);
  }
  informationEx(exception: any, format: string, ...args: any[]) {
    this.event(LogLevel.INFORMATION, format, args, exception);
  }
  debugEx(exception: any, format: string, ...args: any[]) {
    this.event(LogLevel.DEBUG, format, args, exception);
  }
  verboseEx(exception: any, format: string, ...args: any[]) {
    this.event(LogLevel.VERBOSE, format, args, exception);
  }
}

export const logger = new Logger();
// logger.logEvents.subscribe(new ConsoleObserver());
// export const internalLogger = new Logger();
// internalLogger.logEvents.subscribe(new ConsoleObserver());

export const event = (errorLevel: LogLevel, format: string, args: string[], exception?: any) => logger.event(errorLevel, format, args, exception);

export const fatal = (format: string, ...args: any[]) => event(LogLevel.FATAL, format, args);
export const error = (format: string, ...args: any[]) => event(LogLevel.ERROR, format, args);
export const warning = (format: string, ...args: any[]) => event(LogLevel.WARNING, format, args);
export const information = (format: string, ...args: any[]) => event(LogLevel.INFORMATION, format, args);
export const debug = (format: string, ...args: any[]) => event(LogLevel.DEBUG, format, args);
export const verbose = (format: string, ...args: any[]) => event(LogLevel.VERBOSE, format, args);

export const fatalEx = (exception: any, format: string, ...args: any[]) => event(LogLevel.FATAL, format, args, exception);
export const errorEx = (exception: any, format: string, ...args: any[]) => event(LogLevel.ERROR, format, args, exception);
export const warningEx = (exception: any, format: string, ...args: any[]) => event(LogLevel.WARNING, format, args, exception);
export const informationEx = (exception: any, format: string, ...args: any[]) => event(LogLevel.INFORMATION, format, args, exception);
export const debugEx = (exception: any, format: string, ...args: any[]) => event(LogLevel.DEBUG, format, args, exception);
export const verboseEx = (exception: any, format: string, ...args: any[]) => event(LogLevel.VERBOSE, format, args, exception);
