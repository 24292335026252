import ko from 'knockout';
import $ from 'jquery';

ko.bindingHandlers.slideDown = {
  init: (element, valueAccessor) => {
    // Initially set the element to be instantly visible/hidden depending on the value
    const value = valueAccessor();
    $(element).toggle(ko.unwrap(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
  },
  update: (element, valueAccessor) => {
    // Whenever the value subsequently changes, slowly fade the element in or out
    const value = valueAccessor();
    if (ko.unwrap(value)) {
      $(element).animate({
        height: 'show'
      });
    } else {
      $(element).animate({
        height: 'hide'
      });
    }
  }
};
