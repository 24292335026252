import ko from 'knockout';
import Vue, { VueConstructor } from 'vue';
import { ComponentOptions } from 'vue/types/options';

export class KnockoutVueComponent {
  constructor(
    public component: VueConstructor<Vue>,
    public options?: ComponentOptions<Vue>
  ) {}
}

ko.bindingHandlers.vueComponent = {
  init(element: HTMLInputElement, valueAccessor: () => VueConstructor<Vue> | KnockoutVueComponent, allBindings: unknown, viewModel: unknown, bindingContext: KnockoutBindingContext) {
    const rootVue = bindingContext.$root.vue;
    if (!rootVue) throw new Error('Make sure you expose vue on the root component.');
    let options = { el: element, store: rootVue.$store, router: rootVue.$router, vuetify: rootVue.$vuetify.application } as ComponentOptions<Vue>;
    let component: VueConstructor<Vue>;
    const value = valueAccessor();
    if (value instanceof KnockoutVueComponent) {
      options = { ...value.options, ...options };
      component = value.component;
    } else {
      component = value;
    }

    const vueConstructor = Vue.extend(component);
    new vueConstructor(options);
  }
};
