import ko from 'knockout';
import $ from 'jquery';

export interface IFadeVisibleOptions {
  queue: boolean;
}

ko.bindingHandlers.fadeVisible = {
  init: (element, valueAccessor) => {
    const value = ko.unwrap(valueAccessor());
    $(element).toggle(value);
  },
  update: (element, valueAccessor, allBindingsAccessor) => {
    const value = ko.unwrap(valueAccessor());
    const allBindings = allBindingsAccessor();
    const options = allBindings.fadeVisibleOptions ? allBindings.fadeVisibleOptions : {};
    value ? $(element).fadeIn(options) : $(element).fadeOut(options);
  }
};
