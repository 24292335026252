import _ from 'lodash';
import { LogEvent, LogLevel } from './core';
import { IObserver } from '../rx';

export class ConsoleObserver implements IObserver<LogEvent> {
  private _maxJsonLength = -1;

  setMaxJsonLength(length: number) {
    this._maxJsonLength = length;
  }

  private _log = this.createConsoleLogMethod('log');
  private _info = this.createConsoleLogMethod('info');
  private _warn = this.createConsoleLogMethod('warn');
  private _error = this.createConsoleLogMethod('error');

  private createConsoleLogMethod(methodName: keyof Console): (args: any[]) => void {
    const console = window.console;

    const doNothing = () => {
      // do nothing
    };

    if (!console) return doNothing;

    const method = console[methodName];

    if (!method) {
      if (methodName === 'log') return doNothing;
      else return this.createConsoleLogMethod('log');
    }

    if (method.apply) {
      return (args) => {
        try {
          method.apply(console, args);
        } catch (e) {
          // do nothing
        }
      };
    } else {
      return (args) => {
        try {
          Function.prototype.apply.apply(method, [console, args]);
        } catch (e) {
          // do nothing
        }
      };
    }
  }

  onNext(event: LogEvent) {
    const args: any[] = [];
    args.push(event.renderedMessage({ maxJsonLength: this._maxJsonLength }));

    if (_.some(_.keys(event.boundProperties))) {
      args.push('\r\n');
      args.push(event.boundProperties);
    }

    if (event.exception) args.push(event.exception);

    switch (event.level) {
      case LogLevel.FATAL:
      case LogLevel.ERROR:
        this._error(args);
        break;
      case LogLevel.WARNING:
        this._warn(args);
        break;
      case LogLevel.INFORMATION:
        this._info(args);
        break;
      case LogLevel.DEBUG:
      case LogLevel.VERBOSE:
      default:
        this._log(args);
        break;
    }
  }
}
