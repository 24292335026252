import { IRootState } from '../admin/stores';
import { RouteConfig } from 'vue-router';
import { ModuleTree } from 'vuex';

export async function runApp(routes: RouteConfig[], stores: ModuleTree<IRootState>, authenticationPolicies: string[] | null): Promise<Vue> {
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
  Vue.config.productionTip = false;

  if (authenticationPolicies) {
    const { GlobalRoamVueAuthentication } = await import(/* webpackChunkName: "gr-authentication" */ '@gr/authentication');
    Vue.use(await GlobalRoamVueAuthentication({ policies: authenticationPolicies, logOutput: undefined }));
  }

  const { router, store, vuetify } = await import(/* webpackChunkName: "plugins" */ '@/entryPoints/common/plugins');
  const { default: App } = await import(/* webpackChunkName: "App" */ '../common/App.vue');

  return new Vue({
    router: await router(routes),
    store: await store(stores),
    vuetify: await vuetify(),
    render: (h) => h(App)
  }).$mount('#app');
}
