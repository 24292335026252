import ko from 'knockout';

ko.bindingHandlers.checkedRadioToBool = {
  init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
    const observable = valueAccessor();
    const interceptor = ko.pureComputed({
      read: () => {
        return observable().toString();
      },
      write: (newValue) => {
        observable(newValue === 'true');
      },
      owner: this
    });
    ko.applyBindingsToNode(element, { checked: interceptor }, viewModel);
    return { controlsDescendantBindings: false };
  }
};
